import * as React from 'react';
// Mui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useTheme, styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

// utils
import { fNumber } from '../utils/formatNumber';
// SVG
// import { GreenDotSVG } from '@/shared/svg';
// Api
import { RaceEventType } from '../api/RaceCardApi';
import { useState } from 'react';
import { MathFloor } from '@/utils/formatNumberFloor';

type Props = {
  anchorEl: null | HTMLElement;
  sportEventModels: null | Array<RaceEventType>;
  activeEventId: null | string;
  isActiveEventOpenToBet: boolean;
  onChangeEvent: Function;
  // handleClick: (event: React.MouseEvent<HTMLElement) => void,
  handleClose: () => void;
};

export default function SportEventsPopover({
  anchorEl,
  sportEventModels,
  activeEventId,
  isActiveEventOpenToBet,
  onChangeEvent,
  handleClose,
}: Props) {
  const open = Boolean(anchorEl);
  //const open = true;
  const theme = useTheme();
  const [eventsFilter, setEventsFilter] = useState('active');
  const [value, setValue] = React.useState('1');

  const changeEvent = (event: any) => {
    onChangeEvent(event);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  console.log(sportEventModels, 'sportEventModels for socket');
  return (
    <Popover
      aria-labelledby='events-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      marginThreshold={28}
      className='333'
      // BackdropProps={{ invisible: false }}
      PaperProps={{
        elevation: 0,
        sx: {
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingRight: '2px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          border: '1px solid #FFFFFF69',
          background: '#212B36',
          // background:'linear-gradient(142deg, rgba(65, 69, 80, 0.80) 2.64%, rgba(32, 35, 43, 0.80) 96.27%)',
          mt: 1,
          // maxWidth:'400px',
          // width:'350px',
          [theme.breakpoints.up('lg')]: {
            maxWidth: '350px',
            width: '350px',
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '96%',
            width: '100%',
            left: '10px !important',
          },
          '& .MuiAvatar-root': {
            width: 42,
            height: 32,
            ml: 0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            borderTop: '1px solid #FFFFFF69',
            borderLeft: '1px solid #FFFFFF69',
            display: 'block',
            position: 'absolute',
            top: -1,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label='lab API tabs example'>
            <Tab label='Active' value='1' />
            <Tab label='Results' value='2' />
          </TabList>
        </Box>
        <TabPanel value='1' sx={{ padding: '0px' }}>
          <div style={{ overflow: 'hidden', maxWidth: '400px' }}>
            <List
              style={{
                overflowY: 'auto',
                maxHeight: 300,
                maxWidth: 400,
                padding: '10px',
              }}
              className='popoverview'
            >
              {(sportEventModels || [])
                .filter((raceEvent: RaceEventType) => {
                  return raceEvent.whiteConeHoistedFlag === 'N';
                })
                .sort((a: RaceEventType, b: RaceEventType) => {
                  const dateA = new Date(`${a.startDate}T${a.startTime}`);
                  const dateB = new Date(`${b.startDate}T${b.startTime}`);
                  return dateA.getTime() - dateB.getTime(); // Sort in ascending order
                })
                .map((raceEvent: RaceEventType, index: number) => {
                  //const openToBet = activeEventId === raceEvent.id && isActiveEventOpenToBet;
                  const openToBet = raceEvent.stopBetFlag === 'N';
                  const currentEvent = activeEventId;
                  return (
                    <ListItem
                      sx={{
                        borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
                        padding: '0px 12px 0px 8px',
                      }}
                      key={raceEvent.id}
                      disablePadding={true}
                      className={`${
                        raceEvent.id == currentEvent ? 'currentRace' : ''
                      }`}
                    >
                      <ListItemButton
                        sx={{ padding: '8px 0px', gap: '10px' }}
                        className='eventlistview'
                      >
                        <div
                          className='racestatuschange'
                          style={{
                            border: `2px solid ${
                              openToBet ? theme.palette.success.main : '#FF6161'
                            }`,
                            borderRadius: '100%',
                            position: 'relative',
                          }}
                        >
                          <div
                            className='racestatuschangesub'
                            style={{
                              background: `${
                                openToBet
                                  ? theme.palette.success.main
                                  : '#FF6161'
                              }`,
                              height: '10px',
                              width: '10px',
                              borderRadius: '100%',
                              position: 'relative',
                              top: '2px',
                              left: '2px',
                            }}
                          ></div>
                        </div>
                        <div>
                          {/* <div style={{border: `3px solid ${openToBet ? theme.palette.success.main : 'transparent'}`, height:'14px', width:'14px', borderRadius:'100%' }}></div> */}
                          <Stack direction='column' flexWrap='nowrap'>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                gap: '5px',
                              }}
                              onClick={() => changeEvent(raceEvent)}
                            >
                              {/* <div style={{border: `2px solid ${openToBet ? theme.palette.success.main : '#FF6161'}`, height:'14px', width:'14px', borderRadius:'100%' }}></div> */}
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  color: 'rgba(255, 255, 255, 0.80)',
                                }}
                                variant='subtitle2'
                                component='div'
                              >
                                {raceEvent.startTime} -
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  color: 'rgba(255, 255, 255, 0.80)',
                                }}
                                variant='subtitle2'
                                component='div'
                              >
                                {raceEvent.name.replace('Race-', 'Event ')} -
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '400',
                                  color: 'rgba(255, 255, 255, 0.80)',
                                }}
                                variant='caption'
                                component='div'
                              >
                                {raceEvent.venueName}
                              </Typography>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                margin: '0px',
                                lineHeight: '16px',
                              }}
                              onClick={() => changeEvent(raceEvent)}
                            >
                              {openToBet && (
                                <Chip
                                  sx={{
                                    width: 'auto',
                                    padding: '0px',
                                    marginTop: '0px',
                                    background: 'none',
                                    justifyContent: 'flex-start',
                                    border: '0px',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    color: '#66B15F !important',
                                    paddingLeft: '0px',
                                    height: 'auto',
                                    lineHeight: '16px',
                                  }}
                                  size='small'
                                  variant='outlined'
                                  label='Open to bet'
                                  color='success'
                                  clickable={false}
                                  className='chiplabel'
                                />
                              )}
                            </div>
                            {raceEvent.sportType === 'boxing' && (
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: 'rgba(255, 255, 255, 0.80)',
                                  }}
                                  variant='subtitle2'
                                  component='div'
                                >
                                  {raceEvent.runners[0]['Line1']} VS{' '}
                                  {raceEvent.runners[1]['Line1']}
                                </Typography>
                              </div>
                            )}
                          </Stack>
                          {raceEvent.eventResult && (
                            <>
                              <Typography
                                sx={{
                                  marginTop: '2px',
                                  color: '#FF6161',
                                  fontWeight: '500',
                                  fontSize: '12px',
                                  paddingLeft: '0px',
                                  lineHeight: '16px',
                                }}
                                variant='caption'
                                component='div'
                              >
                                Winner :{' '}
                                {`${
                                  raceEvent.eventResult === 'WINNER-1'
                                    ? `X `
                                    : `O`
                                }`}
                              </Typography>
                            </>
                          )}
                          {raceEvent.eventStatus && (
                            <>
                              <Typography
                                sx={{
                                  marginTop: '0px',
                                  color: '#FF6161',
                                  fontWeight: '500',
                                  fontSize: '12px',
                                  paddingLeft: '0px',
                                  lineHeight: '16px',
                                }}
                                variant='caption'
                                component='div'
                              >
                                {raceEvent.odds
                                  ? `Closed at : X(${
                                      MathFloor(raceEvent.odds['WIN-1']) || 0
                                    }) , O(${
                                      MathFloor(raceEvent.odds['WIN-2']) || 0
                                    })`
                                  : ''}
                                {raceEvent.odds === undefined
                                  ? `Closed at : X(0) , O(0)`
                                  : ''}
                              </Typography>
                            </>
                          )}
                        </div>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </TabPanel>
        <TabPanel value='2' sx={{ padding: '0px' }}>
          <div style={{ overflow: 'hidden', maxWidth: '400px' }}>
            <List
              style={{
                overflowY: 'auto',
                maxHeight: 300,
                maxWidth: 400,
                padding: '10px',
              }}
              className='popoverview'
            >
              {(sportEventModels || [])
                .filter((raceEvent: RaceEventType) => {
                  return raceEvent.whiteConeHoistedFlag === 'Y';
                })
                .sort((a: RaceEventType, b: RaceEventType) => {
                  // debugger;
                  const dateA = new Date(`${a.startDate}T${a.startTime}`);
                  const dateB = new Date(`${b.startDate}T${b.startTime}`);
                  return dateB.getTime() - dateA.getTime(); // Sort in ascending order
                })
                .map((raceEvent: RaceEventType, index: number) => {
                  //const openToBet = activeEventId === raceEvent.id && isActiveEventOpenToBet;
                  const openToBet = raceEvent.stopBetFlag === 'N';
                  const currentEvent = activeEventId;
                  return (
                    <ListItem
                      sx={{
                        borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
                        padding: '0px 12px 0px 8px',
                      }}
                      key={raceEvent.id}
                      disablePadding={true}
                      className={`${
                        raceEvent.id == currentEvent ? 'currentRace' : ''
                      }`}
                      // style={{
                      //   borderLeft: `3px solid ${openToBet ? theme.palette.success.main : 'transparent'}`,

                      // }}
                    >
                      <ListItemButton
                        sx={{ padding: '8px 0px', gap: '10px' }}
                        className='eventlistview'
                      >
                        <div
                          className='racestatuschange'
                          style={{
                            border: `2px solid ${
                              openToBet ? theme.palette.success.main : '#FF6161'
                            }`,
                            borderRadius: '100%',
                            position: 'relative',
                          }}
                        >
                          <div
                            className='racestatuschangesub'
                            style={{
                              background: `${
                                openToBet
                                  ? theme.palette.success.main
                                  : '#FF6161'
                              }`,
                              height: '10px',
                              width: '10px',
                              borderRadius: '100%',
                              position: 'relative',
                              top: '2px',
                              left: '2px',
                            }}
                          ></div>
                        </div>
                        <div>
                          {/* <div style={{border: `3px solid ${openToBet ? theme.palette.success.main : 'transparent'}`, height:'14px', width:'14px', borderRadius:'100%' }}></div> */}
                          <Stack direction='column' flexWrap='nowrap'>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                gap: '5px',
                              }}
                              onClick={() => changeEvent(raceEvent)}
                            >
                              {/* <div style={{border: `2px solid ${openToBet ? theme.palette.success.main : '#FF6161'}`, height:'14px', width:'14px', borderRadius:'100%' }}></div> */}
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  color: 'rgba(255, 255, 255, 0.80)',
                                }}
                                variant='subtitle2'
                                component='div'
                              >
                                {raceEvent.startTime} -
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  color: 'rgba(255, 255, 255, 0.80)',
                                }}
                                variant='subtitle2'
                                component='div'
                              >
                                {raceEvent.name.replace('Race-', 'Event ')} -
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '400',
                                  color: 'rgba(255, 255, 255, 0.80)',
                                }}
                                variant='caption'
                                component='div'
                              >
                                {raceEvent.venueName}
                              </Typography>
                              {/* <Typography variant="caption" component="div">
                        {raceEvent.id}  - {raceEvent.currentFlag} - {currentEvent}
                        </Typography> */}
                            </div>
                            <div
                              style={{
                                width: '100%',
                                margin: '0px',
                                lineHeight: '16px',
                              }}
                              onClick={() => changeEvent(raceEvent)}
                            >
                              {openToBet && (
                                <Chip
                                  sx={{
                                    width: 'auto',
                                    padding: '0px',
                                    marginTop: '0px',
                                    background: 'none',
                                    justifyContent: 'flex-start',
                                    border: '0px',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    color: '#66B15F !important',
                                    paddingLeft: '0px',
                                    height: 'auto',
                                    lineHeight: '16px',
                                  }}
                                  size='small'
                                  variant='outlined'
                                  label='Open to bet'
                                  color='success'
                                  clickable={false}
                                  className='chiplabel'
                                />
                              )}
                            </div>
                            {raceEvent.sportType === 'boxing' && (
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '10px',
                                    fontWeight: '500',
                                    color: 'rgba(255, 255, 255, 0.80)',
                                  }}
                                  variant='subtitle2'
                                  component='div'
                                >
                                  {raceEvent.runners[0]['Line1']} VS{' '}
                                  {raceEvent.runners[1]['Line1']}
                                </Typography>
                              </div>
                            )}
                          </Stack>
                          {raceEvent.eventResult && (
                            <>
                              <Typography
                                sx={{
                                  marginTop: '2px',
                                  color: '#FF6161',
                                  fontWeight: '500',
                                  fontSize: '12px',
                                  paddingLeft: '0px',
                                  lineHeight: '16px',
                                }}
                                variant='caption'
                                component='div'
                              >
                                {raceEvent.eventResult !== 'REFUND' &&
                                  `Winner: ${
                                    raceEvent.eventResult === 'WINNER-1'
                                      ? 'X'
                                      : 'O'
                                  }`}

                                {raceEvent.eventResult === 'REFUND' &&
                                  raceEvent.resultType === 'dead-heat' &&
                                  `TIE`}

                                {raceEvent.eventResult === 'REFUND' &&
                                  raceEvent.resultType === 'void-null' && (
                                    <>CANCELLED - {raceEvent.resultMessage}</>
                                  )}
                              </Typography>
                            </>
                          )}
                          {raceEvent.eventStatus && (
                            <>
                              {/* {raceEvent.startDate}-{raceEvent.startTime} */}
                              <Typography
                                sx={{
                                  marginTop: '0px',
                                  color: '#FF6161',
                                  fontWeight: '500',
                                  fontSize: '12px',
                                  paddingLeft: '0px',
                                  lineHeight: '16px',
                                }}
                                variant='caption'
                                component='div'
                              >
                                {raceEvent.odds
                                  ? `Closed at : X(${
                                      MathFloor(raceEvent.odds['WIN-1']) || 0
                                    }) , O(${
                                      MathFloor(raceEvent.odds['WIN-2']) || 0
                                    })`
                                  : ''}
                                {raceEvent.odds === undefined
                                  ? `Closed at : X(0) , O(0)`
                                  : ''}
                              </Typography>
                            </>
                          )}
                        </div>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </TabPanel>
      </TabContext>
    </Popover>
  );
}
