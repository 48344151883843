import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
// Models
import GameModel from '../@types/models/GameModel';
// API
import { apiGetGamesByMenuCategory } from '../api/GameApi';
// Service
import GameService from '../services/GameService';
// Components
import Page from '../components/Page';
import HomeHeader from '../components/HomeHeader';
import GameList from '../components/GameList';
import GameCategoryNavigation, { GameCategoryTabType } from '../components/GameCategoryNavigation';
import Home from './home/home';
// SVG
import {
  CardIconSvg,
  HotIconSvg,
  LiveIconSvg,
  MatchRacingSvg,
  ProvidersIconSvg,
  SlotIconSvg,
  SportIconSvg,
} from '@/shared/svg';
import GameCards from './home/GameCards';
import XOGameCards from './home/XOGameCards';

// TODO move this to MUI-Theme
const themeColor = process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';

const HomePage = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  // state.
  const [categoryTabIndex, setCategoryTabIndex] = useState<number>(0);
  const [providerGameList, setProviderGameList] = useState<null | Array<GameModel>>(null);
  const [menuCardsGameList, setMenuCardsGameList] = useState<null | Array<GameModel>>(null);
  const [menuSlotsGameList, setMenuSlotsGameList] = useState<null | Array<GameModel>>(null);
  const [menuLiveGameList, setMenuLiveGameList] = useState<null | Array<GameModel>>(null);
  const [menuSportsGameList, setMenuSportsGameList] = useState<null | Array<GameModel>>(null);

  const category: Array<GameCategoryTabType> = [{
      label: 'Providers',
      icon: ProvidersIconSvg,
    }, {
      label: 'Cards',
      icon: CardIconSvg,
      types: ['RNG', 'CARD'],
    },
    {
      label: 'Slots',
      icon: SlotIconSvg,
      types: ['SLOTS'],
    },
    {
      label: `Live${!isMobileOrTablet ? ' Games': ''}`,
      icon: LiveIconSvg,
      types: ['LIVE', 'LIVE-DEALER'],
    },
    {
      label: `Sports${!isMobileOrTablet ? ' Bet': ''}`,
      icon: SportIconSvg,
      types: ['SPORTS'],
    }
  ];

  const getCurrentGameList = (): null | Array<GameModel> => {
    let gameModels: Array<GameModel> = null;
    switch (categoryTabIndex) {
      case 0:
        gameModels = providerGameList;
        break;
      case 1:
        gameModels = menuCardsGameList;
        break;
      case 2:
        gameModels = menuSlotsGameList;
        break;
      case 3:
        gameModels = menuLiveGameList;
        break;
      case 4:
        gameModels = menuSportsGameList;
        break;
    }
    return gameModels;
  }

  const getGamesList = async () => {
    switch (categoryTabIndex) {
      case 0:
        if (!providerGameList) {
          const response = await GameService.getGamesData();
          setProviderGameList(response.data);
        }
        break;
      case 1:
        if (!menuCardsGameList) {
          const gameModels: null | Array<GameModel> = await apiGetGamesByMenuCategory('cards');
          setMenuCardsGameList(gameModels);
        }
        break;
      case 2:
        if (!menuSlotsGameList) {
          const gameModels: null | Array<GameModel> = await apiGetGamesByMenuCategory('slots');
          setMenuSlotsGameList(gameModels);
        }
        break;
      case 3:
        if (!menuLiveGameList) {
          const gameModels: null | Array<GameModel> = await apiGetGamesByMenuCategory('live');
          setMenuLiveGameList(gameModels);
        }
        break;
      case 4:
        if (!menuSportsGameList) {
          const gameModels: null | Array<GameModel> = await apiGetGamesByMenuCategory('sports');
          setMenuSportsGameList(gameModels);
        }
        break;
    }
  };

  useEffect(() => {
    getGamesList();
  }, [categoryTabIndex]);

  const gameCategoryNavigation = (
    <GameCategoryNavigation
      showLabels={!isMobileOrTablet}
      tabs={category}
      tabIndex={categoryTabIndex}
      onClick={setCategoryTabIndex}
    />
  );
  return (
    <Page title="Home" favicon={process.env.REACT_APP_THEME === 'fairplay' ? '/icons/partners/fairplay/favicon.ico' : null}>
      
      <Container maxWidth={'lg'} >
        <Box sx={{ marginBottom: 2, marginTop: 2, }}>
          <HomeHeader />
        </Box>

        {process.env.REACT_APP_THEME === 'arionplay' && <XOGameCards />}
        {/*<GameCards />*/}
        {/* {!isMobileOrTablet &&
          <Box sx={{ borderRadius: 1, overflow: 'hidden', border: `1px solid ${themeColor}` }}>
            {gameCategoryNavigation}
          </Box>
        } */}
        {<Box key={categoryTabIndex} sx={{ marginTop: 2 }}>
          {
            categoryTabIndex === 0 ?
              <Home gameList={providerGameList}/> :
              <div>
                <GameList
                  displaySearch={true}
                  games={getCurrentGameList()}
                />
              </div>
          }
        </Box>}
      </Container>
     
      {/* {
        isMobileOrTablet &&
        <Paper
          sx={{
            zIndex: 100,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
          }}
          elevation={1}
        >
          {gameCategoryNavigation}
        </Paper>
      } */}
    </Page>
  );
};

export default HomePage;
