import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useCallback,
  ElementType,
  ComponentType,
  ReactElement,
} from 'react';
import {
  Navigate,
  useRoutes,
  useLocation,
  Outlet,
  Routes,
  Route,
} from 'react-router-dom';
// Redux.
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  setIsAuthenticatedData,
  setUserData,
  setIsKYCStatus,
  setKYCRejectedRemarks,
  setKYCRejectedReason,
  setIsKYCPopup,
} from '@/redux/slices/login';
// Context
import UserContext from './contexts/UserContext';
// Layout.
import Layout from './layout';
// Components.
import LoadingScreen from './components/LoadingScreen';
import Unauthenticated from './components/Unauthenticated';
// Services.
import AuthService, { isAccessTokenAvailable } from './services/AuthService';
import CookieStorageService from './services/CookieStorageService';
import Sockets from './services/SocketService';
import { updateModelAttributes } from './services/SocketModelUpdate';
// Models
import UserModel from './@types/models/UserModel';
import GeoModel from './@types/models/GeoModel';
import Header from './layout/header';
import { StyledFooter } from './pages/landing/style';
// ----------------------------------------------------------------------

export default function Router(): ReactElement | null {
  const dispatch = useAppDispatch();
  const [userModel, setUserModel] = useState<UserModel | null>(null);
  const [geoModel, setGeoModel] = useState<GeoModel | null>(null);
  const [loading, setLoading] = useState(true);
  const isAuthenticated: boolean = isAccessTokenAvailable();

  const privateRoute = (RouteComponent: ComponentType) => {
    // LocalStorageService.removeItem('current_url');
    // LocalStorageService.setItem('current_url', pathname);
    return isAuthenticated ? <RouteComponent /> : <Unauthenticated />;
  };

  //const { userInfo } = useAppSelector(state => state.auth);

  const pageBlockByIP = async () => {
    const geoData = await AuthService.getGeoData();
    const geoModelTmp = geoData ? geoData.data : null;
    console.log('geoModelTmp', geoModelTmp);
    // Forward if needed
    if (geoModelTmp.country !== 'PH' && geoModelTmp.country !== 'IN') {
      if (!window.location.pathname.includes('accessdenied')) {
        window.location.replace('/accessdenied');
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    setGeoModel(geoModelTmp);
  };

  const getUserInfo = async () => {
    const userData = await AuthService.getUserData();
    if (userData.status) {
      // [Context]: Update user model state and the context.
      setUserModel(userData.data);
      // [Socket]: Start subscribing user id.
      Sockets.setSocketForUser(userData.data.id, updateModelAttributes);
      // TODO: Move this to React Context and drop Redux.
      dispatch(setUserData(userData.data));
      dispatch(setIsAuthenticatedData(true));
      dispatch(setIsKYCStatus(userData.data.kycVerified));
      dispatch(setIsKYCPopup(userData.data.kycPopupClose));
      if (
        userData.data.kycVerified !== undefined &&
        userData.data.kycVerified === 'DECLINED'
      ) {
        dispatch(setKYCRejectedRemarks(userData.data.kycDeclinedRemarks));
        dispatch(setKYCRejectedReason(userData.data.kycDeclinedReason));
      }
    }
  };

  useEffect(() => {
    pageBlockByIP();
    if (isAuthenticated) {
      getUserInfo();
    }
  }, []);

  if (loading) {
    return <LoadingScreen />; // Can customize this with a spinner or other loading UI
  }

  return (
    <UserContext.Provider value={{ isAuthenticated, userModel, geoModel }}>
      {useRoutes([
        {
          path: '/',
          element: (
            <Layout>
              <Outlet />
            </Layout>
          ),
          children: [
            { element: <HomePage />, index: true },
            // List of available games.
            { path: 'games/:providerId', element: <GamesPage /> },
            { path: 'authorize', element: <AuthPage /> },
            { path: 'referalregister/:referalcode', element: <Register /> },
            { path: 'cashierform', element: <DepositPlayer /> },
            // Payment
            {
              path: 'payment',
              children: [
                { path: 'return/:id', element: <PaymentReturn /> },
                { path: 'cancel/:id', element: <PaymentCancel /> },
              ],
            },
            // Footer.
            { path: 'terms', element: <Terms /> },
            { path: 'betrules', element: <Betrules /> },
            { path: 'responsiblegaming', element: <Responsiblegaming /> },
            { path: 'privacypolicy', element: <Privacypolicy /> },
            { path: 'aboutus', element: <Aboutus /> },
            { path: 'contactus', element: <Contactus /> },
            // Footer - FAQ.
            {
              path: 'faqs/*',
              element: <Faqs />,
              // children: [
              //   { element: <Faqs />, index: true },
              //   { path: 'details', element: <FaqsDetails /> },
              //   { path: 'gettingstarted', element: <FaqGettingStarted /> },
              //   { path: 'depositswithdrawals', element: <FaqDepositsWithdrawals /> },
              //   { path: 'sportsbetsenquiries', element: <FaqSportsbetsEnquiries /> },
              //   { path: 'accountassistance', element: <FaqAccountAssistance /> },
              //   { path: 'trustsafety', element: <FaqTrustSafety /> },
              //   { path: 'egamesinquiries', element: <FaqEgamesInquiries /> },
              //   { path: 'rtggamesinquiries', element: <FaqRtggamesInquiries /> },
              // ],
            },
            // User context menu.
            { path: 'myactivity', element: <Myactivity /> },
            { path: 'bettinghistory', element: <Bettinghistory /> },
            { path: 'mywallet', element: <Mywallet /> },
            // User context menu - user profile (todo: move to /profile).
            {
              path: 'myaccount',
              children: [
                { element: privateRoute(MyAccount), index: true },
                // Email verification
                { path: 'email/verify', element: <Verifyotp /> },
              ],
            },
          ],
        },
        // Game
        {
          path: '/game',
          element: (
            <Layout hideFooter={true}>
              <Outlet />
            </Layout>
          ),
          children: [
            { path: 'oddeven', element: privateRoute(GameOddEven) },
            { path: 'gamexo/:provider', element: <GameXO /> },
            { path: 'provider/:module', element: privateRoute(GameOddEven) },
            // Game (todo, what is this?).
            { path: 'triplecrownview', element: <TripleCrownView /> },
            // North Alley games has a special path.
            {
              path: 'na/na_oddeven',
              element: <Navigate to='/game/oddeven' replace />,
            },
            // Content (game) provider game (like EVO, WE, RTG).
            { path: ':providerId/:gameId', element: privateRoute(GamePage) },
          ],
        },
        // Racing
        {
          path: 'racing',
          element: (
            <Layout hideFooter={true}>
              <Outlet />
            </Layout>
          ),
          children: [
            { element: privateRoute(Betting), index: true },
            { path: ':id', element: privateRoute(Betting) },
            { path: ':rId/:rNumber', element: privateRoute(Betting) },
          ],
        },
        // Horse racing.
        { path: 'horseracingtote', element: privateRoute(Racing) },
        { path: 'horseracingfixed', element: privateRoute(Racing) },
        // Unsubscribe email.
        { path: '/unsubscribe', element: <Unsubscribe /> },
        // Access denied - limited to a region (like PH).
        { path: '/accessdenied', element: <AccessDenied /> },
        // 404 - Not Found
        {
          path: '/landing',
          element: (
            <>
              <Landing />
              <StyledFooter>
                <span>&#169;</span>2023 ArionPlay. All rights reserved.
              </StyledFooter>
            </>
          ),
        },
        {
          path: '/landing2',
          element: (
            <>
              <Layout>
                <Landing />
              </Layout>
            </>
          ),
        },
        {
          path: '*',
          // element: <LogoOnlyLayout />,
          children: [
            { path: '404', element: <ErrorNotFound /> },
            { path: '*', element: <Navigate to='/404' replace /> },
          ],
        },
        { path: '*', element: <Navigate to='/404' replace /> },
      ])}
    </UserContext.Provider>
  );
}

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

// Pages.
const Aboutus = Loadable(lazy(() => import('./pages/aboutus/aboutus')));
const AccessDenied = Loadable(lazy(() => import('./pages/error-403')));
const Betrules = Loadable(lazy(() => import('./pages/betrules/betrules')));
const Betting = Loadable(lazy(() => import('./pages/betting')));
const Bettinghistory = Loadable(
  lazy(() => import('./pages/bettinghistory/bettinghistory'))
);
const Contactus = Loadable(lazy(() => import('./pages/contact/contactus')));
const ErrorNotFound = Loadable(lazy(() => import('./pages/error-404')));
const Faqs = Loadable(lazy(() => import('./pages/faqs/faqs')));
const GameOddEven = Loadable(lazy(() => import('./pages/GameOddEven')));
const GamePage = Loadable(lazy(() => import('./pages/GamePage')));
const GamesPage = Loadable(lazy(() => import('./pages/GamesPage')));
const AuthPage = Loadable(lazy(() => import('./pages/AuthPage')));
const HomePage = Loadable(lazy(() => import('./pages/HomePage')));
const MyAccount = Loadable(lazy(() => import('./pages/myaccount/myaccount')));
const Myactivity = Loadable(
  lazy(() => import('./pages/myactivity/myactivity'))
);
const Mywallet = Loadable(lazy(() => import('./pages/mywallet/mywallet')));
const PaymentCancel = Loadable(lazy(() => import('./pages/payment/cancel')));
const PaymentReturn = Loadable(lazy(() => import('./pages/payment/return')));
const Privacypolicy = Loadable(
  lazy(() => import('./pages/privacypolicy/privacypolicy'))
);
const Racing = Loadable(lazy(() => import('./pages/racing')));
const Responsiblegaming = Loadable(
  lazy(() => import('./pages/responsible/responsible'))
);
const Terms = Loadable(lazy(() => import('./pages/terms/terms')));
const TripleCrownView = Loadable(
  lazy(() => import('./pages/triplecrownview/triplecrownview'))
);
const Unsubscribe = Loadable(
  lazy(() => import('./pages/unsubscribe/unsubscribe'))
);
const Verifyotp = Loadable(lazy(() => import('./pages/verifyotp/verifyotp')));
const Landing = Loadable(lazy(() => import('./pages/landing/landing')));
const Register = Loadable(lazy(() => import('./pages/referal/Register')));
const DepositPlayer = Loadable(
  lazy(() => import('./pages/depositPlayer/DepositPlayer'))
);
const GameXO = Loadable(lazy(() => import('./pages/GameXO')));
